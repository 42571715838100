import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import agent from '../../agent';
import {CANCEL_FREAK, DELETE_FREAK, FINISH_FREAK, LEAVE_FREAK} from '../../constants/actionTypes';
import {
  isFreakParticipant,
  isFreakOwner, isFeed,
} from '../../utils/freakUtils';
import Button from "@mui/material/Button";
import ConfirmationModal from "../ConfirmationModal";
import { STATUS_CANCELED, STATUS_FINISHED, STATUS_IN_PROGRESS, STATUS_PLANNED } from '../../constants/commonConstants';
import { DEFAULT_THEME_COLOR } from "../../constants/theme";
import useTranslation from "../../customHooks/translations";

const ArticleActions = props => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const freak = props.freak;

  const [isModalOpen, setModalOpen] = useState(false);
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false);
  const [confirmMethod, setConfirmMethod] = useState('');
  const [startButtonMessage, setStartButtonMessage] = useState(translation.freaks.operations.start);

  const finish = () => {
    props.onClickFinish(agent.Freaks.finish(freak.id));
  };
  const publish = () => {
    props.onClickPublish(agent.Freaks.publish(freak.id));
  };
  const del = () => {
    props.onClickDelete(agent.Freaks.del(freak.id));
  };
  const cancel = () => {
    props.onClickCancel(agent.Freaks.cancel(freak.id))
  };
  const edit = () => {
    navigate(`/freak-editor/${freak.slug}`);
  };

  const handleOpenModal = (confirmMethod) => {
    setModalOpen(true);
    setConfirmMethod(confirmMethod);
  };

  const handleLeaveOpenModal = () => {
    setLeaveModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleLeaveCloseModal = () => {
    setLeaveModalOpen(false);
  };

  const handleConfirm = () => {
    if (confirmMethod === 'cancel') {
      cancel()
    }
    if (confirmMethod === 'delete') {
      del();
    }
    handleCloseModal();
  };

  const handleLeaveConfirm = () => {
    const participant = freak?.participants?.find(participant => participant.username === props.currentUser.username);
    props.onClickLeave(agent.Freaks.leave(freak.id, participant.id));
    handleLeaveCloseModal();
  };

  const handleStartClick = freak => {
    agent.Freaks.start(freak.id);
    setStartButtonMessage(translation.freaks.status.inProgress);
  };

  if (props.canModify) {
    return (
      <>
        <Box sx={{flexGrow: 1}}>
          <Stack spacing={2}>
            {isFreakOwner(props.currentUser.username, freak)
              ? <>
                {
                  !isFeed(freak)
                    && (freak?.status === STATUS_PLANNED
                    && isFreakOwner(props.currentUser.username, freak))
                     ? <Button
                      onClick={() => handleStartClick(freak)}
                      style={{
                        color: 'white',
                        borderColor: DEFAULT_THEME_COLOR,
                        backgroundColor: DEFAULT_THEME_COLOR,
                        maxWidth: 300,
                        'z-index': '0',
                        borderRadius: 4,
                      }}
                      variant="outlined"
                    >
                      {startButtonMessage}
                    </Button>
                    : null
                }
                {
                  !isFeed(freak)
                  && freak.status !== STATUS_PLANNED
                  && freak.status !== STATUS_CANCELED
                  && freak.status !== STATUS_FINISHED
                    ? <Button
                      size="md"
                      variant="contained"
                      onClick={() => finish()}
                      style={{
                        maxWidth: 300,
                        backgroundColor: DEFAULT_THEME_COLOR,
                        color: 'white',
                        borderRadius: 4,
                      }}
                      sx={{
                        outline: 'none',
                        '&:focus': {
                          outline: 'none'
                        },
                        '&:hover': {
                          color: DEFAULT_THEME_COLOR,
                          borderColor: DEFAULT_THEME_COLOR,
                        },
                      }}
                    >
                      {translation.buttons.finish}
                    </Button>
                    : null
                }

                {
                  !isFeed(freak)
                   ? <Button
                      size="md"
                      variant="contained"
                      onClick={() => edit()}
                      style={{
                        maxWidth: 300,
                        backgroundColor: DEFAULT_THEME_COLOR,
                        color: 'white',
                        borderRadius: 4,
                      }}
                      sx={{
                        outline: 'none',
                        '&:focus': {
                          outline: 'none'
                        },
                        '&:hover': {
                          color: DEFAULT_THEME_COLOR,
                          borderColor: DEFAULT_THEME_COLOR,
                        },
                      }}
                    >
                      {translation.buttons.edit}
                   </Button>
                    : null
                }

                {
                  !isFeed(freak)
                  && freak.status !== STATUS_IN_PROGRESS
                  && freak.status !== STATUS_FINISHED
                  && freak.status !== STATUS_CANCELED
                    ? <Button
                      size="md"
                      variant="contained"
                      onClick={() => handleOpenModal('cancel')}
                      style={{
                        backgroundColor: DEFAULT_THEME_COLOR,
                        color: 'white',
                        borderRadius: 4,
                        maxWidth: 300,
                      }}
                      sx={{
                        outline: 'none',
                        '&:focus': {
                          outline: 'none'
                        },
                        '&:hover': {
                          color: DEFAULT_THEME_COLOR,
                          borderColor: DEFAULT_THEME_COLOR,
                        },
                      }}
                    >
                      {translation.buttons.cancel}
                    </Button>
                    : null
                }

                {
                  freak.status !== STATUS_IN_PROGRESS
                    ? <Button
                      size="md"
                      variant="contained"
                      onClick={() => handleOpenModal('delete')}
                      style={{
                        maxWidth: 300,
                        backgroundColor: DEFAULT_THEME_COLOR,
                        color: 'white',
                        borderRadius: 4,
                      }}
                      sx={{
                        outline: 'none',
                        '&:focus': {
                          outline: 'none'
                        },
                        '&:hover': {
                          color: DEFAULT_THEME_COLOR,
                          borderColor: DEFAULT_THEME_COLOR,
                        },
                      }}
                    >
                      {translation.buttons.delete}
                    </Button>
                    : null
                }

              </>
              : null
            }
          </Stack>
        </Box>
        <ConfirmationModal isOpen={isModalOpen} onClose={handleCloseModal} onConfirm={handleConfirm}/>
      </>
    );
  }

  return (
    <span>
      {
        isFreakParticipant(props.currentUser.username, freak)
          ? <>
            <Box sx={{ flexGrow: 1 }}>
              <Stack spacing={2}>
                {
                  !isFreakOwner(props.currentUser.username, freak)
                   ? <Button
                      size="md"
                      variant="contained"
                      onClick={() => handleLeaveOpenModal()}
                      style={{
                        minWidth: 150,
                        maxWidth: 300,
                        backgroundColor: DEFAULT_THEME_COLOR,
                        color: 'white',
                        borderRadius: 4,
                      }}
                      sx={{
                        outline: 'none',
                        '&:focus': {
                          outline: 'none'
                        },
                        '&:hover': {
                          color: DEFAULT_THEME_COLOR,
                          borderColor: DEFAULT_THEME_COLOR,
                        },
                      }}
                    >
                      {translation.buttons.leave}
                    </Button>
                   : null
                }

                <Button
                  size="md"
                  variant="contained"
                  onClick={() => navigate('/messages')}
                  style={{
                    minWidth: 150,
                    maxWidth: 300,
                    backgroundColor: DEFAULT_THEME_COLOR,
                    color: 'white',
                    borderRadius: 4,
                  }}
                  sx={{
                    outline: 'none',
                    '&:focus': {
                      outline: 'none'
                    },
                    '&:hover': {
                      color: DEFAULT_THEME_COLOR,
                      borderColor: DEFAULT_THEME_COLOR,
                    },
                  }}
                >
                  {translation.buttons.chat}
                </Button>
              </Stack>
            </Box>
            <ConfirmationModal
              isOpen={isLeaveModalOpen}
              onClose={handleLeaveCloseModal}
              onConfirm={handleLeaveConfirm}
            />
          </>
          : null
      }
    </span>
  );
};

const mapStateToProps = state => ({
  ...state.freak,
  currentUser: state.common.currentUser
});

const mapDispatchToProps = dispatch => ({
  onClickDelete: payload =>
    dispatch({type: DELETE_FREAK, payload}),
  onClickCancel: payload =>
    dispatch({type: CANCEL_FREAK, payload}),
  onClickFinish: payload =>
    dispatch({type: FINISH_FREAK, payload}),
  onClickPublish: payload =>
    dispatch({type: FINISH_FREAK, payload}),
  onClickLeave: payload =>
    dispatch({type: LEAVE_FREAK, payload}),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleActions);
