import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import RouteIcon from '@mui/icons-material/Route';
import CheckIcon from '@mui/icons-material/Check';
import * as toGeoJSON from "@tmcw/togeojson";

import { DEFAULT_THEME_COLOR } from "../../constants/theme";
import useTranslation from "../../customHooks/translations";
import UploadGpxPreview from "./UploadGpxPreview";
import agent from "../../agent";
import DeleteIcon from "@mui/icons-material/Delete";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const UploadGpxComponent = (props) => {
    const translation = useTranslation();
    const [gpx, setGpx] = useState(props.gpx);
    const [file, setFile] = useState(null);

    const readGpx = event => {
        const file = event.target.files[0];
        if (!file) return;

        setFile(file);

        const reader = new FileReader();
        reader.onload = (e) => {
            const parser = new DOMParser();
            const xml = parser.parseFromString(e.target.result, "application/xml");
            const geojson = toGeoJSON.gpx(xml);

            if (geojson.features.length > 0) {
                const coords = geojson.features[0].geometry.coordinates.map(coord => [coord[1], coord[0]]);
                setGpx(coords);
                props.setRoute(coords);
            }
        };
        reader.readAsText(file);
    };

    const handleDelete = () => {
        setGpx(null);
        setFile(null);
        props.setRoute(null);
        document.getElementById("gpx-file-input").value = "";
    };

    return (
        <>
            {
                gpx
                    ? <UploadGpxPreview route={gpx} />
                    : null
            }
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                style={{
                    minWidth: 150,
                    maxWidth: 300,
                    backgroundColor: DEFAULT_THEME_COLOR,
                    color: 'white',
                    borderRadius: 4,
                    margin: 10,
                    marginLeft: 0,
                }}
                startIcon={<RouteIcon />}
            >
                {translation.buttons.uploadGpx}
                <VisuallyHiddenInput
                    id="gpx-file-input"
                    type="file"
                    onChange={(event) => {
                        readGpx(event);
                    }}
                />
            </Button>
            {
                gpx && !props.gpx
                    ? <Button
                        size="md"
                        variant="contained"
                        onClick={async () => {
                            await agent.Freaks.createRoute(props.freakSlug, gpx);
                        }}
                        style={{
                            minWidth: 150,
                            maxWidth: 300,
                            backgroundColor: DEFAULT_THEME_COLOR,
                            color: 'white',
                            borderRadius: 4,
                            margin: 10,
                        }}
                        startIcon={<CheckIcon />}
                        sx={{
                            outline: 'none',
                            '&:focus': {
                                outline: 'none'
                            },
                            '&:hover': {
                                color: DEFAULT_THEME_COLOR,
                                borderColor: DEFAULT_THEME_COLOR,
                            },
                        }}
                    >
                        {translation.buttons.confirmGpx}
                    </Button>
                    : null
            }
            {
                gpx ?
                    <Button
                        sx={{
                            backgroundColor: DEFAULT_THEME_COLOR,
                            color: 'white',
                            borderColor: DEFAULT_THEME_COLOR,
                            'z-index': '0',
                            '&:hover': {
                                color: DEFAULT_THEME_COLOR,
                                borderColor: DEFAULT_THEME_COLOR,
                            }
                        }}
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        onClick={handleDelete}
                    >
                        {translation.freakEditor.deleteRoute}
                    </Button>
                    : null
            }
        </>
    )
};

export default UploadGpxComponent;
