import React, {useEffect, useRef, useState} from 'react';
import Box from "@mui/material/Box";
import {MapContainer, Marker, Polyline, TileLayer, useMap, useMapEvents} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import {getLatLon} from "../../utils/freakUtils";
import {Icon} from "leaflet";

const myLocationIcon = new Icon({
  iconUrl: '/icons/location-pin.png',
  iconSize: [32, 32],
  iconAnchor: [16, 32]
});

const freakLocationIcon = new Icon({
  iconUrl: '/icons/pin.png',
  iconSize: [32, 32],
  iconAnchor: [16, 32]
});

const UploadGpxPreview = (props) => {
  const [position, setPosition] = useState([50.258598, 19.020420]);
  const [mapZoom, setMapZoom] = useState(7);
  const mapRef = useRef(null);

  useEffect(() => {
    const setCurrentPosition = async () => {
      await getLatLon((position) => {
        setPosition([position.latitude, position.longitude]);
      }, () => {
      });
    };

    setCurrentPosition()
  }, []);

  const ZoomHandler = () => {
    useMapEvents({
      zoomend: () => {
        const currentZoom = mapRef.current.getZoom();
        setMapZoom(currentZoom);
      },
    });

    return null;
  };

  function RecenterAutomatically({position}) {
    const map = useMap();
    useEffect(() => {
      if (position) {
        map.setView(position);
      }
    }, [position, map]);

    return null;
  }

  return (
    <Box sx={{
      overflow: 'hidden',
    }}>
      <MapContainer
        center={position}
        zoom={mapZoom}
        style={{
          height: 300,
          width: '100wh',
        }}
        ref={mapRef}
        attributionControl={false}
        key={0}
        whenReady={() => {
          setTimeout(() => {
            mapRef.current?.invalidateSize();
          }, 500);
        }}
      >
        <ZoomHandler/>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup
          chunkedLoading
        >
          {
            props?.route
              ? <Polyline
                pathOptions={{
                  color: DEFAULT_THEME_COLOR,
                  weight: 4,
                  opacity: 0.9,
                  lineJoin: 'round',
                  lineCap: 'round'
                }}
                positions={props?.route}
              />
              : null
          }
          {position ? <Marker position={position} icon={myLocationIcon}/> : null}
          {props?.route?.length ? <Marker position={props?.route[0]} icon={freakLocationIcon}/> : null}
          {props?.route?.length ? <Marker position={props?.route[props?.route.length - 1]} icon={freakLocationIcon}/> : null}
          {props?.route?.length ? <RecenterAutomatically position={props?.route[Math.round(props?.route.length / 2)]}/> : null}
        </MarkerClusterGroup>
      </MapContainer>
    </Box>
  )
};

export default UploadGpxPreview;
