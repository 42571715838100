import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./styles.css";

import {
  FacebookEmbed,
  YouTubeEmbed,
  InstagramEmbed,
  XEmbed
} from "react-social-media-embed";

const CarouselComponent = (props) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const items = [
    ...(props.images || []),
    ...(props.facebookEmbeds || []),
    ...(props.youtubeEmbeds || []),
    ...(props.instagramEmbeds || []),
    ...(props.xEmbeds || [])
  ];

  return (
    <Carousel
      responsive={responsive}
      arrows={items.length > 1}
      autoPlay={false}
      swipeable={items.length > 1}
      draggable={true}
      showDots={false}
      infinite={true}
      partialVisible={false}
      dotListClass="custom-dot-list-style"
    >
      {
        props.images
          ? props.images.map((image, index) => (
            <div className="slider" key={index}>
              <img src={image.imageUrl} alt="freak" />
            </div>
          ))
          : null
      }

      {
        props.facebookEmbeds
          ? props.facebookEmbeds.map(facebookEmbed => (
            facebookEmbed
              ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <FacebookEmbed url={facebookEmbed} width={400} />
                </div>
              : null
          ))
          : null
      }

      {
        props.youtubeEmbeds
         ? props.youtubeEmbeds.map(youtubeEmbed => (
            youtubeEmbed
             ? <div style={{ display: 'flex', justifyContent: 'center', marginLeft: 5, margingRight: 5 }}>
                <YouTubeEmbed url={youtubeEmbed} width={'100%'} />
               </div>
             : null
          ))
         : null
      }

      {
        props.instagramEmbeds
          ? props.instagramEmbeds.map(instagramEmbed => (
            instagramEmbed
            ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                <InstagramEmbed url={instagramEmbed} width={400} />
              </div>
            : null
          ))
          : null
      }

      {
        props.xEmbeds
          ? props.xEmbeds.map(xEmbed => (
            xEmbed
              ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                <XEmbed url={xEmbed} width={400} />
              </div>
              : null
          ))
          : null
      }

    </Carousel>
  )
};

export default CarouselComponent;
