import React from "react";

import SportsMotorsportsOutlinedIcon from "@mui/icons-material/SportsMotorsportsOutlined";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import PhishingIcon from "@mui/icons-material/Phishing";
import HikingIcon from "@mui/icons-material/Hiking";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import PetsIcon from "@mui/icons-material/Pets";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import SellIcon from "@mui/icons-material/Sell";
import CodeIcon from "@mui/icons-material/Code";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import YardIcon from '@mui/icons-material/Yard';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import BedroomBabyIcon from '@mui/icons-material/BedroomBaby';
import MovieIcon from '@mui/icons-material/Movie';
import BurstModeIcon from '@mui/icons-material/BurstMode';

import { FEED_DAILY, FEED_MONTHLY, FEED_WEEKLY, FEED_YEARLY } from "../constants/commonConstants";
import Avatar from "@mui/material/Avatar";
import {DEFAULT_THEME_COLOR, FEED_DAILY_COLOR} from "../constants/theme";

export const isFreakParticipant = (currentUserName, freak) =>
  freak.author.username === currentUserName ||
  freak.participants.find(participant => participant.username === currentUserName && participant.status === 'approved'
  );

export const isFreakHubParticipantOrAuthor = (userId, freakHub) =>
  freakHub.authorId === userId || freakHub.participants.find(participant => participant.userId === userId);

export const isFreakHubAuthor = (userId, freakHub) => freakHub.authorId === userId;

export const isFreakParticipantDeclined = (currentUserName, freak) => freak.participants.find(participant => participant.username === currentUserName && participant.status === 'declined');

export const isFreakOwner = (currentUserName, freak) => freak.author.username === currentUserName;

export const getLocationError = (error) => {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      return "User denied the request for Geolocation."
      break;
    case error.POSITION_UNAVAILABLE:
      return "Location information is unavailable."
      break;
    case error.TIMEOUT:
      return "The request to get user location timed out."
      break;
    default:
      return "An unknown error occurred."
      break;
  }
};

export const setupGeoLocation = (setGeolocation, setGeoErrorLocation) => {
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const {latitude, longitude} = position.coords;
        setGeolocation({latitude, longitude});
      },
      (error) => {
        setGeoErrorLocation(getLocationError(error));
      }
    );
  } else {
    setGeoErrorLocation('Geolocation is not supported by your browser');
  }
};

export const getLatLon = async (setGeolocation, setGeoErrorLocation) => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const {latitude, longitude} = position.coords;
        if (setGeolocation) {
            setGeolocation({latitude, longitude});
        }
        resolve({latitude, longitude});
      },
      (error) => {
        if (setGeoErrorLocation) {
          setGeoErrorLocation(getLocationError(error));
        }
        reject(error);
      }
    );
  });
};

export const getMapObject = async () => {
  const latLon = await getLatLon();

  const location = {
    lat: latLon.latitude,
    lng: latLon.longitude
  };
  const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${location.lat}&lon=${location.lng}&format=json`);
  const results = await response.json();

  const placeId = results?.place_id;
  const mapObject = {
    title: placeId,
    description: results?.display_name,
    coordinates: {
      latitude: location.lat,
      longitude: location.lng
    }
  };

  return mapObject;
};

export const getGoogleMapsLink = (latitude, longitude) => {
  // Construct the URL with the latitude and longitude parameters
  const url = `https://www.google.com/maps?q=${latitude},${longitude}`;

  // Return the generated URL
  return url;
};

export const getAppleMapsLinks = (latitude, longitude) => {
  const url  = `http://maps.apple.com/?q=${latitude},${longitude}`;

  return url;
}
export const validateYouTubeLink = (youtubeLink, setIsValidYouTubeLink, setYouTubeLink) => {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const isValidLink = youtubeRegex.test(youtubeLink);

  if (isValidLink) {
    const youTubeVideoMatch = youtubeLink.match(youtubeRegex);
    setIsValidYouTubeLink(youTubeVideoMatch && youTubeVideoMatch[5]);
    setYouTubeLink(youtubeLink);
  } else {
    setIsValidYouTubeLink(false);
    setYouTubeLink(youtubeLink);
  }
};

export const validateYouTubeChannelLink = (youtubeLink, setIsValidYouTubeLink, setYouTubeLink) => {
  const youtubeChannelRegex = /^(https?:\/\/)?(www\.)?(youtube\.com).+/;
  const isValidLink = youtubeChannelRegex.test(youtubeLink);

  setIsValidYouTubeLink(isValidLink);
  setYouTubeLink(youtubeLink);
};

export const validateYouTubeFeed = (youtubeFeed, setIsValidYouTubeFeed, setYouTubeFeed) => {
  const youtubeFeedRegex = /^@.+/;
  const isValidFeed = youtubeFeedRegex.test(youtubeFeed);

  setIsValidYouTubeFeed(isValidFeed);
  setYouTubeFeed(youtubeFeed);
};

export const getYouTubeLinkVideoId = (youTubeLink) => {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const youTubeVideoMatch = youTubeLink.match(youtubeRegex);
  if (!youTubeVideoMatch) {
    return;
  }

  const youTubeVideoId = youTubeVideoMatch[5];
  return youTubeVideoId;
};

export const handleAddYouTubeLink = (youTubeLink, linksYoutube, setLinksYoutube, setYouTubeLink) => {
  const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const youTubeVideoMatch = youTubeLink.match(youtubeRegex);
  if (!youTubeVideoMatch) {
    return;
  }

  const youTubeVideoId = youTubeVideoMatch[5];

  const alreadyExists = linksYoutube.find((link) => link === youTubeVideoId);
  let updatedLinksYoutube = linksYoutube;
  if (!alreadyExists) {
    updatedLinksYoutube.push(youTubeLink);
    setLinksYoutube(updatedLinksYoutube);
  }
  setYouTubeLink('');

  return updatedLinksYoutube.map(updatedLink => {
    if (typeof updatedLink === 'object') {
      return updatedLink.name;
    }
    return updatedLink;
  });
};

export const validateXLink = (xLink, setIsValidXLink, setXLink) => {
  // valid x link
  // https://x.com/FortniteNwsHub/status/1891866251040788547

  const xRegex = /^https:\/\/(?:www\.)?x\.com\/.+$/;
  const isValidLink = xRegex.test(xLink);

  setIsValidXLink(isValidLink);
  setXLink(xLink);
};

export const handleAddXLink = (xLink, linksX, setLinksX, setXLink) => {
  const alreadyExists = linksX.find((link) => link === xLink);
  let updatedLinksX = linksX;
  if (!alreadyExists) {
    updatedLinksX.push(xLink);
    setLinksX(updatedLinksX);
  }
  setXLink('');

  return updatedLinksX.map(updatedLink => {
    if (typeof updatedLink === 'object') {
      return updatedLink.url;
    }
    return updatedLink;
  });
};

export const validateFacebookLink = (facebookLink, setIsValidFacebookLink, setFacebookLink) => {
  // valid facebook embed link:
  // https://www.facebook.com/gdzieobejrzefilm/posts/pfbid0aAqs1PfPhJWKn124pRSRsYWBkr33txkTRWtUS6UyJeNvAapDTrz8HgYXhPRARgSNl

  // valid share link:
  // https://www.facebook.com/share/p/7eq2rD8a9jieHWF7/?mibextid=WC7FNe

  const facebookRegex = /https:\/\/www\.facebook\.com\/(?:.+\/)?(?:posts|videos|reel|share)\/[^/]+/;
  const isValidLink = facebookRegex.test(facebookLink);

  setIsValidFacebookLink(isValidLink);
  setFacebookLink(facebookLink);
};

export const validateFacebookPlaceLink = (facebookLink, setIsValidFacebookLink, setFacebookLink) => {
  const facebookPlaceRegex = /https:\/\/www\.facebook\.com\/.+/;
  const isValidLink = facebookPlaceRegex.test(facebookLink);

  setIsValidFacebookLink(isValidLink);
  setFacebookLink(facebookLink);
};

export const validatePlaceLink = (placeLink, setIsValidPlaceLink, setPlaceLink) => {
  const placeRegex = /https:.+/;

  const isValidLink = placeRegex.test(placeLink);

  setIsValidPlaceLink(isValidLink);
  setPlaceLink(placeLink);
};

export const handleAddFacebookLink = (facebookLink, linksFacebook, setLinksFacebook, setFacebookLink) => {
  const alreadyExists = linksFacebook.find((link) => link === facebookLink);
  let updatedLinksFacebook = linksFacebook;
  if (!alreadyExists) {
    updatedLinksFacebook.push(facebookLink);
    setLinksFacebook(updatedLinksFacebook);
  }
  setFacebookLink('');

  return updatedLinksFacebook.map(updatedLink => {
    if (typeof updatedLink === 'object') {
      return updatedLink.url;
    }
    return updatedLink;
  });
};

export const validateInstagramLink = (instagramLink, setIsValidInstagramLink, setInstagramLink) => {
  const instagramRegex = /https:\/\/www\.instagram\.com\//;
  const isValidLink = instagramRegex.test(instagramLink);

  setIsValidInstagramLink(isValidLink);
  setInstagramLink(instagramLink);

  // expected
  // https://www.instagram.com/reel/C4igV8CIVsf/?utm_source=ig_embed&amp;utm_campaign=loading
  // or
  // https://www.instagram.com/p/C4sG20utpLH/?utm_source=ig_embed&amp;utm_campaign=loading
};

export const handleAddInstagramLink = (instagramLink, linksInstagram, setLinksInstagram, setInstagramLink) => {
  const alreadyExists = linksInstagram.find((link) => link === instagramLink);
  let updatedLinksInstagram = linksInstagram;
  if (!alreadyExists) {
    updatedLinksInstagram.push(instagramLink);
    setLinksInstagram(updatedLinksInstagram);
  }
  setInstagramLink('');

  return updatedLinksInstagram.map(updatedLink => {
    if (typeof updatedLink === 'object') {
      return updatedLink.url;
    }
    return updatedLink;
  });
};

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const earthRadiusKm = 6371; // Radius of the Earth in kilometers
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadiusKm * c; // Distance in kilometers
  return parseFloat(distance.toFixed(2));
};

export const expandBounds = (bounds, distanceKm = 100) => {
  const kmToLat = distanceKm / 111; // Latitude degrees per km
  const kmToLon = distanceKm / (111 * Math.cos((bounds.northEastLat + bounds.southWestLat) / 2 * (Math.PI / 180))); // Longitude degrees per km

  return {
    northEastLat: bounds.northEastLat + kmToLat,
    northEastLon: bounds.northEastLon + kmToLon,
    southWestLat: bounds.southWestLat - kmToLat,
    southWestLon: bounds.southWestLon - kmToLon,
  };
}

export const getDistanceFromLatLonInMeters = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3; // Earth's radius in meters
  const dLat = (lat2 - lat1) * Math.PI / 180;
  const dLon = (lon2 - lon1) * Math.PI / 180;
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
};

const deg2rad = (deg) => deg * (Math.PI / 180);

export const calculateDistanceToFreak = (geolocation, freak, translation) => {
  const lat1 = geolocation?.latitude;
  const lon1 = geolocation?.longitude;

  const lat2 = freak?.map?.coordinates?.latitude;
  const lon2 = freak?.map?.coordinates?.longitude;

  if (lat1 && lon1 && lat2 && lon2) {
    const distance = calculateDistance(lat1, lon1, lat2, lon2).toFixed(1);

    return `${distance} km ${translation.freakLocalization.distance}`;
  }
  return '';
};

const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

export const getFreakName = (context, translations) => {
  const contextName = `context${capitalize(context)}`;
  return translations?.settings[contextName];
};

export const getContextIcon = context => {
  if (!context || context === 'wof') {
    return (
      <SportsMotorsportsOutlinedIcon />
    );
  } else if (context === 'bike') {
    return (
      <DirectionsBikeIcon />
    );
  } else if (context === 'fishing') {
    return (
      <PhishingIcon />
    );
  } else if (context === 'trip') {
    return (
      <HikingIcon />
    );
  } else if (context === 'fit') {
    return (
      <FitnessCenterIcon />
    )
  } else if (context === 'running') {
    return (
      <DirectionsRunIcon />
    );
  } else if (context === 'dogs') {
    return (
      <PetsIcon />
    );
  } else if (context === 'games') {
    return (
      <SportsEsportsIcon />
    );
  } else if (context === 'books') {
    return (
      <AutoStoriesIcon />
    );
  } else if (context === 'food') {
    return (
      <RamenDiningIcon />
    );
  } else if (context === 'shopping') {
    return (
      <SellIcon />
    );
  } else if (context === 'tech') {
    return (
      <CodeIcon />
    );
  } else if (context === 'motorization') {
    return (
      <DirectionsCarIcon />
    );
    // contextPath = path || './../car-engine.png';
  } else if (context === 'gardening') {
    return (
      <YardIcon />
    );
  } else if (context === 'music') {
    return (
      <MusicNoteIcon />
    );
  } else if (context === 'sport') {
    return (
      <SportsMmaIcon />
    );
  } else if (context === 'winterSports') {
    return (
      <DownhillSkiingIcon />
    );
  } else if (context === 'horseRiding') {
    return (
      <BedroomBabyIcon />
    );
  } else if (context === 'movies') {
    return (
      <MovieIcon />
    );
  } else if (context === 'series') {
    return (
      <BurstModeIcon />
    );
  }
};

export const getFreakIcon = (context, onClick, path) => getContextIcon(context);

export const isFeed = freak => {
  return freak.type === FEED_DAILY
    || freak.type === FEED_WEEKLY
    || freak.type === FEED_MONTHLY
    || freak.type === FEED_YEARLY;
};
