import {Link, useLocation, useNavigate} from 'react-router-dom';
import ListErrors from './ListErrors';
import React, { useState, useEffect } from 'react';
import agent from '../agent';
import { connect } from 'react-redux';
import {
  UPDATE_FIELD_AUTH,
  LOGIN,
  LOGIN_PAGE_UNLOADED, BOTTOM_NAVIGATION_VISIBILITY
} from '../constants/actionTypes';
import TopBar from "./TopBar";
import useTranslation from "../customHooks/translations";
import LogoComponent from "./LogoComponent";
import { sanitizeInput } from "../utils/sanitize";
import { useAuth0 } from "@auth0/auth0-react";

export const Login = (props) => {
  const translation = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const redirectTo = new URLSearchParams(location.search).get('redirectTo') || '/freak';
  const { loginWithRedirect } = useAuth0();

  const [returnTo, setReturnTo] = useState('/freak');

  let changeEmail;
  let changePassword;
  let submitForm;

  const [_email, setEmail] = useState('');
  const [_password, setPassword] = useState('');

  useEffect(() => {
    props.setBottomNavigationVisible(false);

    if (props.token && redirectTo) {
      navigate(redirectTo);
    }

    setReturnTo(redirectTo);
  }, [
    props.context
  ]);

  useEffect(() => {
    changeEmail = ev => props.onChangeEmail(sanitizeInput(ev.target.value));

    changePassword = ev => props.onChangePassword(sanitizeInput(ev.target.value));
    submitForm = (email, password) => ev => {
      ev.preventDefault();
      props.onSubmit(email, password, redirectTo);
    };

    props.onUnload();
  }, []);

  return (
    <>
      <TopBar />
      <div className="auth-page">
        <div className="container page">
          <LogoComponent />

          <div className="row">

            <div className="col-md-6 offset-md-3 col-xs-12">
              <h1 className="text-xs-center">{translation.login.signIn}</h1>
              <p className="text-xs-center">
                <Link to="/register">
                  {translation.login.needAccount}
                </Link>
              </p>

              <ListErrors errors={props.errors} />

              <p className="text-xs-center">
                <button
                  className="btn btn-lg btn-primary"
                  onClick={() => loginWithRedirect({
                    appState: {
                      returnTo
                    }
                  })}
                >
                  {translation.login.buttons.signIn}
                </button>
                <button
                  className="btn btn-lg btn-secondary"
                  onClick={() => navigate('/places-restricted')}
                >
                  {translation.login.tryUsFirst}
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  ...state.auth,
  token: state.common.token,
});

const mapDispatchToProps = dispatch => ({
  onChangeEmail: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),
  onChangePassword: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value }),
  onSubmit: (email, password, redirectTo) => {
    dispatch({ type: LOGIN, payload: agent.Auth.login(email, password), redirectTo })
  },
  onUnload: () =>
    dispatch({ type: LOGIN_PAGE_UNLOADED }),
  setBottomNavigationVisible: isVisible => dispatch({
    type: BOTTOM_NAVIGATION_VISIBILITY,
    payload: isVisible
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
