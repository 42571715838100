import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import ListErrors from './ListErrors';
import agent from '../agent';
import { connect } from 'react-redux';
import {
  UPDATE_FIELD_AUTH,
  REGISTER,
  REGISTER_PAGE_UNLOADED, BOTTOM_NAVIGATION_VISIBILITY
} from '../constants/actionTypes';
import TopBar from "./TopBar";
import useTranslation from "../customHooks/translations";
import LogoComponent from "./LogoComponent";
import ReCAPTCHA from "react-google-recaptcha";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import { DEFAULT_THEME_COLOR } from "../constants/theme";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {capitalizeFirstLetter} from "../utils/commonUtils";
import {validatePassword} from "../utils/passwordUtils";

const Register = (props) => {
  const translation = useTranslation();
  const navigate = useNavigate();
  const [emailSent, setEmailSent] = useState(false);
  const [availableContexts, setAvailableContexts] = useState([]);
  const [selectedContext, setSelectedContext] = useState('wof');
  const recaptchaRef = React.createRef();

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    navigate('/login');
  };

  const changeEmail = ev => props.onChangeEmail(ev.target.value);
  const changePassword = ev => props.onChangePassword(ev.target.value);
  const changeUsername = ev => props.onChangeUsername(ev.target.value);
  const submitForm = (username, email, password, context) => async ev => {
    ev.preventDefault();
    const token = recaptchaRef.current.getValue();
    props.onSubmit(username, email, password, token, context, '/login');

    props.onUnload();
  };

  useEffect(() => {
    props.setBottomNavigationVisible(false);

    const fetchData = async () => {
        const results = await agent.Freaks.availableContexts();

      setAvailableContexts(results);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setEmailSent(false);
  }, [
    props.email,
    props.password,
    props.username,
  ]);

  const email = props.email;
  const password = props.password;
  const username = props.username;

  return (
    <>
      <TopBar/>
      <div className="auth-page">
        <div className="container page">
          <LogoComponent/>
          <div className="row">

            <div className="col-md-6 offset-md-3 col-xs-12">
              <h1 className="text-xs-center">{translation.register.signUp}</h1>
              <p className="text-xs-center">
                <Link to="/login">
                  {translation.register.haveAnAccount}
                </Link>
              </p>

              <ListErrors errors={props.errors} />

              <form onSubmit={submitForm(username, email, password, selectedContext)}>
                <fieldset>

                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="text"
                      placeholder={translation.register.placeholders.username}
                      value={props.username}
                      onChange={changeUsername}/>
                  </fieldset>

                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="email"
                      placeholder="Email"
                      value={props.email}
                      onChange={changeEmail}/>
                  </fieldset>

                  <fieldset className="form-group">
                    <input
                      className="form-control form-control-lg"
                      type="password"
                      placeholder={translation.register.placeholders.password}
                      value={props.password}
                      onChange={changePassword}/>
                  </fieldset>

                  <fieldset className="form-group">
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">{translation.register.defaultContext}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedContext}
                        label="context"
                        onChange={(event) => setSelectedContext(event.target.value)}
                      >
                        {
                          availableContexts?.map(availableContext => (
                            <MenuItem
                              value={availableContext}>{translation.settings[`context${capitalizeFirstLetter(availableContext)}`]}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </fieldset>

                  {
                    emailSent && !props.errors
                      ? <fieldset className="form-group">
                        {translation.register.emailSent}
                      </fieldset>
                      : null
                  }

                  <FormGroup>
                    <FormControlLabel
                      required
                      control={<Checkbox/>}
                      label={
                        <span>
                          {translation.register.termsOfService}{' '}
                          <Link to="/terms-of-service.html" target="_blank">
                          {translation.register.termsOfServiceTitle}
                            </Link>
                          {' '}{translation.register.termsOfServiceAnd}{' '}
                          <Link to="/privacy-policy.html" target="_blank">
                            {translation.register.privacyPolicy}
                          </Link>
                          </span>
                      }
                    />
                  </FormGroup>

                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECHAPTA_SITE_KEY}
                  />

                  <button
                    className="btn btn-lg btn-primary pull-xs-right"
                    type="submit"
                    disabled={props.inProgress}>
                    {translation.register.signUp}
                  </button>

                </fieldset>
              </form>
            </div>

            <Modal
              open={isOpen}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24, p: 4
              }}
              >
                <Typography id="modal-modal-description">
                  {translation.modals.confirmation.emailHasBeenSent}
                </Typography>
                <ButtonGroup
                  sx={{
                    display: 'flex',
                    marginTop: 1,
                  }}
                  orientation="horizontal"
                >
                  <Button
                    onClick={handleClose}
                    variant="contained"
                    size="md"
                    style={{
                      width: '100%',
                      backgroundColor: DEFAULT_THEME_COLOR,
                      color: 'white',
                      alignSelf: 'flex-end',
                      'z-index': '0',
                      borderRadius: 4,
                      marginRight: 1,
                      marginBottom: 10,
                    }}
                  >
                    {translation.modals.confirmation.ok}
                  </Button>
                </ButtonGroup>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({ ...state.auth });

const mapDispatchToProps = dispatch => ({
  onChangeEmail: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'email', value }),
  onChangePassword: value => {
    const errors = validatePassword(value);
    if (errors && errors.length) {
      dispatch({ type: UPDATE_FIELD_AUTH, error: true, key: 'password', value, payload: { errors } });
    } else {
      dispatch({ type: UPDATE_FIELD_AUTH, key: 'password', value });
    }
  },
  onChangeUsername: value =>
    dispatch({ type: UPDATE_FIELD_AUTH, key: 'username', value }),
  onSubmit: (username, email, password, token, context, redirectTo) => {
    const payload = agent.Auth.register(username, email, password, token, context);
    dispatch({ type: REGISTER, payload, redirectTo })
  },
  onUnload: () =>
    dispatch({ type: REGISTER_PAGE_UNLOADED }),
  setBottomNavigationVisible: isVisible => dispatch({
    type: BOTTOM_NAVIGATION_VISIBILITY,
    payload: isVisible
  }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
