import React, {useState} from 'react';
import {DEFAULT_THEME_COLOR} from "../../constants/theme";
import Button from "@mui/material/Button";
import useTranslation from "../../customHooks/translations";
import {Global} from "@emotion/react";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import {styled} from "@mui/material/styles";
import {Card, IconButton} from "@mui/material";
import CardContent from "@mui/joy/CardContent";
import {ButtonGroup, Container} from "@mui/material";
import GifIcon from "@mui/icons-material/Gif";
import agent from "../../agent";
import GiphyComponent from "./GiphyComponent";
import {ADD_COMMENT} from "../../constants/actionTypes";
import {connect} from "react-redux";
import {Editor} from "react-draft-wysiwyg";
import {EditorState, convertToRaw} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import "./style.css"
import {getLanguageByBrowser} from "../../utils/languageUtils";
import MessageIcon from '@mui/icons-material/Message';
import Box from "@mui/material/Box";

const StyledBox = styled('div')(({theme}) => ({}));

const Puller = styled('div')(({theme}) => ({
    width: 30,
    height: 6,
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));

const PostCommentComponent = (props) => {
    const translation = useTranslation();
    const drawerBleeding = 0;

    const [open, setOpen] = useState(false);
    const [gifsVisible, setGifsVisible] = useState(false);
    const [stickersVisible, setStickersVisible] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const handleChange = (event) => {
        setEditorState(event)
    };

    const createComment = (ev, gifId = null) => {
        ev?.preventDefault();

        let payload = {};
        const contentState = editorState.getCurrentContent();
        const raw = convertToRaw(contentState);
        const html = draftToHtml(raw);
        payload = agent.Comments.createForFreak(
            props.slug,
            {body: html, gifId, replyTo: props.replyTo}
        );
        props.onSubmit(payload);
        setOpen(false);
    };

    return (
        <>
            <Button
                size="md"
                variant="contained"
                onClick={() => setOpen(true)}
                style={{
                    backgroundColor: DEFAULT_THEME_COLOR,
                    color: 'white'
                }}
                startIcon={<MessageIcon />}
            >
                {props.text}
            </Button>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `calc(100% - ${drawerBleeding}px)`,
                        overflow: 'visible',
                    },
                }}
            />
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <StyledBox
                    sx={{
                        position: 'absolute',
                        top: -drawerBleeding,
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                        marginTop: 'env(safe-area-inset-top, 50px)',
                    }}
                >
                    <Puller/>
                    <Card sx={{
                        height: '100vh',
                        boxShadow: 'none',
                    }}>
                        <CardContent>
                            <Container maxWidth="lg" sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '20px 0',
                            }}>
                                <ButtonGroup
                                    fullWidth
                                    orientation="horizontal"
                                >
                                    <Button
                                        variant="outlined"
                                        style={{
                                            backgroundColor: 'white',
                                            color: DEFAULT_THEME_COLOR,
                                            'z-index': '0',
                                            borderRadius: 4,
                                            maxWidth: 200,
                                            flexGrow: 1,
                                            margin: 5,
                                        }}
                                        onClick={() => setOpen(false)}
                                    >
                                        {translation.comments.cancel}
                                    </Button>

                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: DEFAULT_THEME_COLOR,
                                            color: 'white',
                                            borderColor: DEFAULT_THEME_COLOR,
                                            'z-index': '0',
                                            borderRadius: 4,
                                            maxWidth: 200,
                                            flexGrow: 1,
                                            margin: 5,
                                            '&:hover': {
                                                color: DEFAULT_THEME_COLOR,
                                                borderColor: DEFAULT_THEME_COLOR,
                                            }
                                        }}
                                        onClick={() => {
                                            createComment();
                                            setOpen(false)
                                        }}
                                    >
                                        {translation.comments.add}
                                    </Button>
                                </ButtonGroup>
                            </Container>
                            <Box
                                sx={{
                                    marginLeft: 2,
                                }}
                            >
                                <Editor
                                    style={{
                                        height: gifsVisible ? '30vh' : '80vh',
                                    }}
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName editor-border"
                                    onEditorStateChange={handleChange}
                                    toolbar={{
                                        options: [
                                            'link',
                                            'emoji',
                                        ]
                                    }}
                                    localization={{
                                        locale: getLanguageByBrowser(),
                                    }}
                                />
                            </Box>
                            <IconButton
                                aria-label="gif"
                                onClick={() => {
                                    setGifsVisible(!gifsVisible);
                                    setStickersVisible(false);
                                }}
                                style={{
                                    borderRadius: '50%',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                    color: 'white',
                                    backgroundColor: DEFAULT_THEME_COLOR,
                                    width: 30,
                                    height: 30,
                                    marginLeft: 10,
                                }}
                            >
                                <GifIcon/>
                            </IconButton>
                            {
                                gifsVisible
                                    ? <GiphyComponent
                                        createComment={(ev, gif) => {
                                            createComment(ev, gif.id);
                                        }}
                                        setGifsVisible={setGifsVisible}
                                    />
                                    : null
                            }
                        </CardContent>
                    </Card>
                </StyledBox>
            </SwipeableDrawer>
        </>
    )
};

const mapStateToProps = state => ({
    ...state
});

const mapDispatchToProps = dispatch => ({
    onSubmit: payload =>
        dispatch({type: ADD_COMMENT, payload})
});

export default connect(mapStateToProps, mapDispatchToProps)(PostCommentComponent);

